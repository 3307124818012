<template>
  <div>
    <div class="container-fluid layout-container--fcoe-header">
      <div class="fcoe-header row columns">
        <div class="fcoe-nameplate">
          <h5 class="fcoe-nameplate__title"><a href="https://www.norwalkacts.org/">Norwalk ACTS</a></h5>
          <!-- <h6 class="fcoe-nameplate__superintendent"><a href="https://fcoe.org/superintendent">Jim A. Yovino, Superintendent of Schools</a></h6> -->
        </div>
      </div>
    </div>
    <b-navbar toggleable="lg" type="dark" variant="matchub">
      <b-navbar-brand href="#"><img src="@/assets/MatchHub.png" class="header-image"/></b-navbar-brand>
      <b-navbar-brand href="#"><img src="@/assets/client-logo-Horizontal.png" class="header-image"/></b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item><b-button size="lg" squared @click="requestPortalLogin">Login</b-button></b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <b-container fluid class="p-0 m-0">
      <router-view></router-view>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "HeaderBar",
  methods: {
    async requestPortalLogin() {
      await this.$msal.signIn();
      if (this.$msal.getIsAuthenticated())
        this.$router.push({ name: "Landing" });
    },
  },
};
</script>

<style scoped>
.bg-matchub {
    background-color: #E7E3DB !important;
}

#website-logo {
  float: left;
  height: 60px;
}
#website-logo-text {
  float: right;
  text-align: left;
  font-size: 20px;
}

.layout-header {
  margin-bottom: 20px;
  margin-top: 20px;
}

.header-image {
  height: 50px;
  width: auto;
}

.layout-container--fcoe-header {
    background: #fafafa;
    border-bottom: 1px solid #e6e6e6;
}

.row.row.columns {
    float: none;
}
.column.row.row, .row.row.columns {
    float: none;
}
@media screen and (min-width: 64em) {
  .fcoe-header {
      height: 64px;
  }
  .fcoe-header {
      position: relative;
  }
}

.fcoe-nameplate {
    /* background: url(~@/assets/FCSS-Insignia.svg) no-repeat 17px 15px; */
    background-size: 39px;
}

.fcoe-nameplate {
    min-height: 75px;
    padding-left: 37px;
    padding-top: 11px;
}

.fcoe-nameplate__superintendent, .fcoe-nameplate__title {
    margin-left: 35px;
}

.fcoe-nameplate__title {
    font-size: .88235rem;
}

.fcoe-nameplate__title {
    font-weight: 600;
    font-size: 1.05882rem;
    line-height: 1.17647rem;
    margin: 0;
}

.fcoe-nameplate__superintendent, .fcoe-nameplate__title {
    margin-left: 35px;
}

.fcoe-nameplate__superintendent {
    font-size: .64706rem;
}

.fcoe-nameplate__superintendent {
    font-size: .64706rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

.fcoe-nameplate a {
    display: block;
    padding-top: 2px;
    color: #737373;
}
</style>