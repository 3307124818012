'use strict'

import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store/index';
import router from '@/router/index';

import MSALPlugin from '@/plugins/msal-plugin';
import ApiPlugin from '@/plugins/api-plugin';
import LoggingPlugin from '@/plugins/logging-plugin';
import SignalRPlugin from '@/plugins/signalr-plugin';

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

import VueTour from 'vue-tour'
require('vue-tour/dist/vue-tour.css')

import VueWordCloud from 'vuewordcloud'
Vue.component(VueWordCloud.name, VueWordCloud)
Vue.config.productionTip = false

/* eslint-disable no-new */
// Internal plugins
const loggingOptions = {
  sentryDSN: 'https://43ff6513251e4276937b1b95e3e4e783@o409996.ingest.sentry.io/5647586', //process.env.SENTRY_DSN,
  sentryTraceSampleRate: parseFloat(process.env.SENTRY_SDK_TRACE_SAMEPL_RATE)
};
Vue.use(LoggingPlugin, loggingOptions);

const msalOptions = {
  clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
  loginAuthority:  process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
  passwordAuthority: process.env.VUE_APP_MSAL_PASSWORD_RESET_AUTHORITY,
  knownAuthority: process.env.VUE_APP_MSAL_KNOWN_AUTHORITY
};
Vue.use(MSALPlugin, msalOptions);

const apiOptions = {
  apiBaseUrl: process.env.VUE_APP_RESTFUL_API,
  secureApiBaseUrl: process.env.VUE_APP_SECURE_API
};
Vue.use(ApiPlugin, apiOptions);
Vue.use(SignalRPlugin, {});

// Thirdparty plugins
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSidebarMenu);
Vue.use(VueTour);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
