<template>
  <div id="home-page">
    <b-container>
      <b-row class="justify-content-center">
        <b-carousel
          id="image-carousel"
          style="text-shadow: 0px 0px 2px #000"
          fade
          indicators
          img-height="100"
        >
          <b-carousel-slide><template #img>
              <img class="center-image" src="@/assets/slide1.jpg">
          </template></b-carousel-slide>
          <b-carousel-slide><template #img>
              <img class="center-image" src="@/assets/slide2.jpg">
          </template></b-carousel-slide>
          <b-carousel-slide><template #img>
              <img class="center-image" src="@/assets/slide3.jpg">
          </template></b-carousel-slide>
          <b-carousel-slide><template #img>
              <img class="center-image" src="@/assets/slide5.jpg">
          </template></b-carousel-slide>
          <b-carousel-slide><template #img>
              <img class="center-image" src="@/assets/slide6.jpg">
          </template></b-carousel-slide>
          <b-carousel-slide><template #img>
              <img class="center-image" src="@/assets/slide7.jpg">
          </template></b-carousel-slide>
          <b-carousel-slide><template #img>
              <img class="center-image" src="@/assets/slide8.jpg">
          </template></b-carousel-slide>
        </b-carousel>
      </b-row>

      <b-row class="justify-content-center mt-5 text-left">
        <b-col>
          <h2>MatchHub is part of Norwalk ACTS.</h2>
          <p>By creating shared unique IDs using matching strategies applied to individual level data we can maximize coordination, streamline access for students and families and understand how education and workforce pathways are operating.</p>
          <p>Having access to timely data at the person level ensures that services and supports meet community needs and contribute to positive and equitable outcomes.</p>
          <p>Matching individual data is part of the Referral/Care Coordination System development and the foundation for meeting the goals of Norwalk ACTS.</p>
        </b-col>
        <b-col>
          <img src="@/assets/URIDLogo.png" style="height: 70%; width: auto;"/>
        </b-col>
      </b-row>
    </b-container>
    
    <b-row>
      <footer>
        <b-container fluid class="text-uppercase text-center p-5">
          <b-row class="justify-content-center">
            <h1>&bull; Our commitment to security and safety &bull;</h1>
          </b-row>
          <b-row class="justify-content-center">
            <p>Just as Norwalk ACTS places a priority on the wellbeing of our community’s children and families, we place the same priority on the care of their data.</p>
          </b-row>
          <b-row class="justify-content-center">
            <p>We are committed to ensure that data assets are utilized responsibly and effectively to support and improve the lives of children and adults in our community.</p>
          </b-row>
        </b-container>
        <b-container>
          <b-row class="justify-content-center">
            <b-col sm="4">
              <img src="@/assets/client-logo.png" class="header-image w-100"/>
            </b-col>
          </b-row>
        </b-container>
      </footer>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {      
      containers: [],
    };
  }
};
</script>
<style scoped>
#home-page {
  overflow-x: hidden !important;
}

.home-header {
  margin-bottom: 50px;
}

.carousel-item {
  width: auto;
  height: 500px;
  max-width: 100vw;
}

.center-image {
  transform: translate(0%, -20%);
}

footer {
  padding: 2rem 0;
  width: 100%;
  text-align: center;
  background-color:#E7E3DB;
  color: #204575;
}
</style>